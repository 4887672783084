import styled from 'styled-components'
import {
  space,
  measurement,
  display,
  position,
} from 'style/fns'

const Image = styled.img`
  ${position}
  ${space}
  ${measurement}
  ${display}
`

export default Image

import styled from 'styled-components'

import {
  colors,
  space,
  display,
  border,
  typography,
  measurement
} from 'style/fns'

const Button = styled.button`
  ${border}
  ${typography}
  ${measurement}
  ${display}
  ${colors}
  ${space}
  border: none;
`

export default Button

import styled from 'styled-components'

import {
  space,
  display,
  flex,
  position,
  background,
  colors
} from 'style/fns'

const Section = styled.section`
  ${colors}
  ${flex}
  ${space}
  ${display}
  ${position}
  ${background}
`

export default Section

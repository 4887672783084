import styled from 'styled-components'
import {background, space, measurement, flex, position} from 'style/fns'

const Row = styled.div`
  ${position}
  ${flex}
  ${space}
  ${measurement}
  ${background}
  display: flex;
  flex-wrap: wrap;
`

export default Row

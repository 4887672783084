import styled from 'styled-components'
import {
  space,
  typography,
  colors,
  display,
  position,
  border
} from 'style/fns'

const Text = styled.p`
  ${colors}
  ${border}
  ${display}
  ${position}
  ${typography}
  ${space}
`

export default Text

import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-7.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/BusinessOwnerTips`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Tips for Aspiring Business Owners',
  },
  {
    property: 'og:description',
    content:
      'Nagbabalak ka bang magkaroon ng sariling negosyo? Read these tips.',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="Tips for Aspiring Business Owners" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          TIPS FOR ASPIRING BUSINESS OWNERS
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            HINDI LANG SAGING ANG MAY PUSO!
          </Heading>
          <Text>
            Dapat mayroon din sa negosyo! Una sa lahat, you need to love what
            you do. LOVE YOUR BUSINESS. Magre-reflect ito sa iyong customers,
            katrabaho, at kita. Set goals and remind yourself everyday why you
            even started. At syempre, kailangan consistent ka sa ginagawa mo.
            Passion is the key!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            SAY NO TO BAD VIBES!
          </Heading>
          <Text>
            Sa negosyo, dapat lagi kang naka-smile! ‘Yung tipong pagkagising mo
            pa lang sa umaga, positive thoughts na agad ang pumapasok sa isip
            mo. Para kapag humarap ka na sa iyong customers o mga suki, ramdam
            nila na malugod mo silang binibigyan ng serbisyo. Simpleng ngiti
            lang pero ang laki ng impact!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            KUMUHA NG BUSINESS NA SURE ANG KITA
          </Heading>
          <Text>
            Let’s admit this, hindi lahat ng business ay may 100% ROI. Kung
            gusto mong magkaroon ng sariling negosyo, do your research at
            pag-isipang mabuti kung anong negosyo ang papatok sa inyong lugar.
            Buti na lang may Digipay! For just P100, may online tindahan ka na
            for bills payment, e-load, e-pins, mobile money at microinsurance.
            Maliit lang ang puhunan pero sigurado ang kita!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            KNOW YOUR MARKET
          </Heading>
          <Text>
            Ang iyong customers ay hindi lang basta customers. They are your
            walking research. Kung nakikita mong wala pang Digipay outlet sa
            inyong barangay, isang malaking opportunity ito para makapagsimula
            ng negosyo. Dahil sa’yo, hindi na nila kailangang pumila o sumugod
            sa traffic para lang makapagbayad ng bills. Kumita ka na, nakatulong
            ka pa!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            DON’T STOP BELIEVING...AND LEARNING!
          </Heading>
          <Text>
            Dapat ikaw ang number one fan ng negosyo mo! Kung naniniwala ka sa
            ginagawa mo, this will pass down to your customers. At higit sa
            lahat, always make room for improvements. Huwag matakot magkamali at
            matuto sa mga bagong bagay at experiences.
          </Text>

          <Text>
            Ready ka na bang magkaroon ng sariling negosyo? Be a Digipay Agent
            today! Click{' '}
            <a
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
              id="inline-link"
            >
              here
            </a>{' '}
            to sign up.
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy

import React from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import { Footer } from 'components'
import Header from './Header'
import theme from 'style/theme'
import EXO2 from 'fonts/Exo2.ttf'
import FBThumb from 'images/bg/hero-banner.jpg'
import HostName from '../data/static/HostNames'

const GlobalStyle = createGlobalStyle`
 @font-face {
    font-family: 'Exo2';
    font-weight: 100 400 700;
    src: url(${EXO2}) format('truetype');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Exo2', georgia, sans-serif;
  }
`

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: 'https://www.digipay.ph',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Digipay',
  },
  {
    property: 'og:description',
    content: 'E-Payment platform na Mabilis, Madali, at Mura!',
  },
  {
    property: 'og:image',
    content: FBThumb,
  },
]

const SITE_LINKS = [
  { route: '/', text: 'HOME' },
  { route: '/faqs', text: 'FAQs' },
  { route: '/terms', text: 'TERMS' },
  { route: '/policy', text: 'POLICY' },
  { route: '/promo', text: 'PROMO' },
  {
    external: true,
    text: 'SIGN IN',
    route: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-in`,
  },
  {
    external: true,
    text: 'SIGN UP',
    route: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`,
  },
]

function LayoutContent(props) {
  const { title, children } = props
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />

        <Helmet title={title} meta={SITE_META}>
          <html lang="en" />
        </Helmet>

        <Header links={SITE_LINKS} />

        <main role="main">{children}</main>
        <Footer />
      </>
    </ThemeProvider>
  )
}

const SITE_TITLE = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = props => (
  <StaticQuery
    query={SITE_TITLE}
    render={data => (
      <LayoutContent title={data.site.siteMetadata.title}>
        {props.children}
      </LayoutContent>
    )}
  />
)

export default Layout

import styled from 'styled-components'
import {
  space,
  typography,
  colors,
  display,
  position,
  border
} from 'style/fns'

const Heading = styled.h1`
  ${colors}
  ${border}
  ${display}
  ${position}
  ${typography}
  ${space}
`

export default Heading

import styled from 'styled-components'

import {
  background,
  space,
  border,
  colsize,
  display,
  flex,
  position,
  measurement,
  typography,
} from 'style/fns'

const Column = styled.div`
  flex: 1 0 auto;
  ${position}
  ${measurement}
  ${typography}
  ${background}
  ${space}
  ${display}
  ${flex}
  ${colsize}
  ${border}
`

export default Column

const keyword = (kw, ac=0) =>
  (obj, val, i) =>
  ({...obj, [kw + (ac + i)]: val})

const px = n => n + 'px'
const percent =  (_, n, max) => ((n + 1) / max.length) * 100 + '%'

const breakpoints = {
  xs: '21em',
  sm: '40em',
  md: '52em',
  lg: '65em',
  xl: '90em'
}

const LIGHT_COLORS = [
  '#f8f8f8',
  '#efefef',
  '#e7e7e7'
].reduce(keyword('light-'), {})

const colors = {
  primary: '#EB2426',
  dark: '#5B5B5B',
  ...LIGHT_COLORS
}

const space = [
  0, 4, 8,
  16, 32, 64,
  128, 256, 512
].map(px).reduce(keyword('sp'), {})

const fsizes = [
  12, 14, 16,
  20, 24, 32,
  48, 64, 72
].map(px).reduce(keyword('fsize-'), {})

const columns = new Array(12).fill(null)
const colsizes = columns.map(percent).reduce(keyword('col-', 1), {})

export default {
  breakpoints,
  space,
  fsizes,
  colsizes,
  colors
}

import styled from 'styled-components'

import {
  colors,
  space,
  display,
  border,
  typography,
  measurement
} from 'style/fns'

const Anchor = styled.a`
  ${colors}
  ${typography}
  ${measurement}
  ${display}
  ${space}
  ${border}
  text-decoration: none;
`

export default Anchor

import styled from 'styled-components'

import {
  space,
  display,
  flex,
  position,
  border,
  measurement,
  background,
  colors,
  typography
} from 'style/fns'

const UList = styled.ul`
  ${colors}
  ${typography}
  ${flex}
  ${space}
  ${display}
  ${position}
  ${background}
  ${border}
  ${measurement}
`

export default UList
